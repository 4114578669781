import httpClient from "../httpClient"

import { formatData } from "../request"
import { encodeRequestParameters } from "../request"

import { fobsFormat, usedAndUnusedFobsFormat } from "../formats"

// get the list of fobs
export function getFobs(data) {
    data = formatData(fobsFormat, data)
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/badge", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

// delete a particular fob
export function deleteFob(fobId) {
    return httpClient
        .delete("/badge/" + fobId, {})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function getUsedAndUnusedFobs(data) {
    data = formatData(usedAndUnusedFobsFormat, data)
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/keys/used", { params })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

export function exportFobs(data) {
    data = formatData(usedAndUnusedFobsFormat, data)
    const params = encodeRequestParameters(data)
    return httpClient
        .get("/keys/used/export", { params, responseType: "blob" })
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
